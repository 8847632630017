/* eslint-disable no-undef */
import ru from "../rus";
import en from "../eng";

describe("test for compare RU and EN language packs ../src/languages/", () => {
  const keysRu = Object.keys(ru);
  const keysEn = Object.keys(en);
  it("compare RU modules equal to EN modules", () => {
    expect(keysRu).toEqual(expect.arrayContaining(keysEn));
    expect(keysEn).toEqual(expect.arrayContaining(keysRu));
  });

  for (const key of keysRu) {
    const deepKeysRu = Object.keys(ru[key]);
    const deepKeysEn = Object.keys(en[key]);
    it(`compare ${key} lang module for RU and EN`, () => {
      expect(deepKeysRu).toEqual(expect.arrayContaining(deepKeysEn));
      expect(deepKeysEn).toEqual(expect.arrayContaining(deepKeysRu));
    });
  }
});
